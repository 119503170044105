import { render, staticRenderFns } from "./Visit-trips.vue?vue&type=template&id=022fac48&scoped=true"
import script from "./Visit-trips.vue?vue&type=script&lang=js"
export * from "./Visit-trips.vue?vue&type=script&lang=js"
import style0 from "./Visit-trips.vue?vue&type=style&index=0&id=022fac48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022fac48",
  null
  
)

export default component.exports